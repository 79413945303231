@import url(https://fonts.googleapis.com/css?family=Saira:500);

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 80vw;
    -webkit-justify-content: end;
            justify-content: end;
    margin-right: 2rem;
}


.fa-bars {
    color: #fff;
}



@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        width: 100vw;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #242222;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }



    .fa-times {
        color: #fff;
        font-size: 2rem;
    }


}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', arial, "微軟正黑體", "Microsoft JhengHei";
}




.nav-menu {
    letter-spacing: 0.1em;
}

/*#region Home*/

.Home1 {
    background-image: url(/static/media/JPGHOME背景-1.c9617bf9.jpg);
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 80vh; /* For 80% screen height */
    width: 100vw; /* For 100% screen width */
    color: #fff;
    font-size: 100px;
}

.Home2 {
    background-image: url(/static/media/JPGHOME背景-2.73dd3ced.jpg);
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh; /* For 100% screen height */
    width: 100vw; /* For 100% screen width */
    color: #fff;
    font-size: 100px;
}
/*#endregion */

/*#region swiper*/
.swiper-container1 {
    height: 100vh;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0);
    object-fit: contain;
    margin-top: -90px;
}

.swiper-container2 {
    height: 70vh;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0);
    object-fit: contain;
    object-fit: contain;
}

.swiper1 {
    height: 100vh;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0);
    object-fit: contain;
    margin-top: 0px;
}

.swiper2 {
    height: 100%;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0);
    object-fit: contain;
    margin-top: 0px;
}

.SwiperSlideImg1 {
    height: 100%;
    width: 100%;
}

.SwiperSlideImg2 {
    height: 100%;
    width: 100%;
}
/*#endregion */


/*#region Products*/


.Product {
    width: 100vw; /* For 100% screen width */
}

.ProductDetail {
    width: 100vw; /* For 100% screen width */
}

.div90 {
    width: 90%;
    margin: 0px auto;
    margin-top: 100px;
}

.div100 {
    width: 100%;
}


.liSpec {
    padding-top: 10px;
    font-size: 12px;
    line-height: 22px;
}

.ulSpec {
    display: block;
    list-style-type: square;
    margin: 15px;
}

.ImagePaperCenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
/*#endregion */

/*#region Company*/
.Company1 {
    background-image: url(/static/media/Company.8fcd7724.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: -webkit-flex;
    display: flex;
    height: 90vh;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: #fff;
    font-size: 100px;
    margin-top: -80px;
    height: 100vh; /* For 100% screen height */
    width: 100vw; /* For 100% screen width */
    background-attachment: fixed;
}

.Company2 {
    background-color: #000000;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
    height: 60vh; /* For 100% screen height */
    width: 100vw; /* For 100% screen width */
}

.Company2Container {
    max-width: 100vw;
    height: 100%;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    display: -webkit-flex;
    display: flex; /* ★ */
    -webkit-align-items: center;
            align-items: center; /* ★ */
}


.Com {
    height: 100vh;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    object-fit: contain;
}

    .Com > div {
        color: #fff;
        font-weight: 700;
        font-family: 'Noto Sans TC', arial, "微軟正黑體", "Microsoft JhengHei";
    }

    .Com > p {
        margin-top: 2px;
        color: #fff;
        font-size: 32px;
        font-family: 'Noto Sans', arial, "微軟正黑體", "Microsoft JhengHei";
    }

.FirstWord {
    font-family: 'Noto Sans TC', arial, "微軟正黑體", "Microsoft JhengHei";
}

.OtherWord {
    font-size: 40px;
    font-weight: 100;
    font-family: 'Noto Sans TC', arial, "微軟正黑體", "Microsoft JhengHei";
}

.C1 {
    height: 100%;
    width: 100%;
    background-image: url(/static/media/Company1.ece07fef.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.C2 {
    height: 100%;
    width: 100%;
}

.C2content {
    padding-top: 60px;
    padding-left: 60px;
    padding-right: 60px;
}

.C2title {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 34px;
    line-height: 35px;
    font-weight: bold;
    font-family: 'Montserrat', arial, "微軟正黑體", "Microsoft JhengHei";
}

.C2content {
    font-size: 18px;
    line-height: 36px;
    font-family: 'Montserrat', arial, "微軟正黑體", "Microsoft JhengHei";
}

/*#endregion */

/*#region HOPE*/
.HO {
    width: 100vw;
    height: 600px;
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
}

.PE {
    width: 100vw;
    height: 600px;
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
}

.H {
    background-image: url(/static/media/Honesty.9ad82f3a.jpg);
    background-color: rgba(0,0,0,.3);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    width: 100%;
    color: #FFFFFF;
    font-size: 12px;
    display: -webkit-flex;
    display: flex; /* ★ */
    -webkit-align-items: center;
            align-items: center; /* ★ */
}


.O {
    background-image: url(/static/media/OneStopService.ae1a3459.jpg);
    background-color: rgba(0,0,0,.3);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    width: 100%;
    color: #FFFFFF;
    font-size: 12px;
    display: -webkit-flex;
    display: flex; /* ★ */
    -webkit-align-items: center;
            align-items: center; /* ★ */
}

.P {
    background-image: url(/static/media/Profession.c28ab219.jpg);
    background-color: rgba(0,0,0,.3);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    width: 100%;
    color: #FFFFFF;
    font-size: 12px;
    display: -webkit-flex;
    display: flex; /* ★ */
    -webkit-align-items: center;
            align-items: center; /* ★ */
}

.E {
    background-image: url(/static/media/EnvironmentFriendly.e06acc76.jpg);
    background-color: rgba(0,0,0,.3);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    width: 100%;
    color: #FFFFFF;
    font-size: 12px;
    display: -webkit-flex;
    display: flex; /* ★ */
    -webkit-align-items: center;
            align-items: center; /* ★ */
}

.H:hover {
    background-color: rgba(0,0,0,.6);
}

.O:hover {
    background-color: rgba(0,0,0,.6);
}

.P:hover {
    background-color: rgba(0,0,0,.6);
}

.E:hover {
    background-color: rgba(0,0,0,.6);
}

.sloganTitle {
    font-size: 35px;
    font-weight: bold;
    line-height: 50px;
    font-family: 'Montserrat', arial, "微軟正黑體", "Microsoft JhengHei";
}

.sloganCotent {
    font-size: 20px;
    line-height: 34px;
    font-family: 'Montserrat', arial, "微軟正黑體", "Microsoft JhengHei";
    text-align: left;
}

.slogan {
    text-align: center;
    -webkit-transform: translateY(10%);
            transform: translateY(10%);
    margin: 0px auto;
    width: 80%;
}

.sloganHr {
    border: 0;
    height: 4px;
    background-color: #ff6a00;
    margin-top: 12px;
    margin-bottom: 12px;
}

/*#endregion */

/*#region .Services*/

.Services {
    display: -webkit-flex;
    display: flex;
    background-image: url(/static/media/Service.6d683c0e.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    margin-top: -80px;
    height: 100vh; /* For 100% screen height */
    width: 100vw; /* For 100% screen width */
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-attachment: fixed;
}

.Services2 {
    background-image: url(/static/media/Service1.6c3e0c2c.jpg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 30px;
    color: #fff;
    height: 50vh; /* For 100% screen height */
    width: 50vw; /* For 100% screen width */
}

.Services3 {
    display: block;
    width: 70%;
}

.Services3title {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 35px;
    font-family: 'Montserrat', arial, "微軟正黑體", "Microsoft JhengHei";
}

.Services3content {
    font-size: 14px;
    line-height: 24px;
    font-weight: 100;
    font-family: 'Montserrat', arial, "微軟正黑體", "Microsoft JhengHei";
}


/*#endregion */

/*#region Contact*/

.Contact {
    display: -webkit-flex;
    display: flex;
    background-image: url(/static/media/Contact.48b30d6a.jpg);
    background-size: 100vw 70vh;
    background-repeat: no-repeat;
    margin-top: -80px;
    color: rgba(0,0,0,.7);
    font-size: 100px;
    height: 70vh; /* For 100% screen height */
    width: 100vw; /* For 100% screen width */
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: left;
            justify-content: left;
    background-attachment: fixed;
}

.ContactTitle {
    padding-left: 100px;
}

.ContactContainer {
    max-width: 70vw;
    margin: 0 auto;
    margin-top: 100px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
}

.ContactNote {
    width: 100%;
    line-height: 26px;
    color: rgb(28, 27, 27);
    font-size: 24px;
    line-height: 24px;
    color: #4D4D4D;
    margin: 0 auto;
    padding-bottom: 50px;
    font-family: 'Montserrat', arial, "微軟正黑體", "Microsoft JhengHei";
}


    .ContactNote > p {
        margin-top: 20px;
    }

.ContactInfo {
    width: 100%;
    line-height: 26px;
    color: rgb(28, 27, 27);
    line-height: 24px;
    margin: 0 auto;
    padding-bottom: 50px;
    font-family: 'Montserrat', arial, "微軟正黑體", "Microsoft JhengHei";
}

.ContactInfo > p {
    margin-top: 20px;
}

.ContactInfoTitle {
    font-size: 34px;
    line-height: 44px;
    font-weight: 100;
}



.ContactAddr {
    width: 100%;
    line-height: 26px;
    color: #676767;
    font-size: 16px;
}

    .ContactAddr > p {
        margin-top: 20px;
    }

.ContactInput {
    line-height: 45px;
    padding: 0 1em;
}

.ContactLabel {
    font-size: 14px;
    line-height: 26px;
    font-weight: 100;
}

/*#endregion */

/*#region ButtonBlock*/
.ButtonNote {
    padding-top: 30px;
    width: 100%;
    line-height: 26px;
    font-size: 12px;
    color: #CCCCCC;
    font-family: 'Montserrat', arial, "微軟正黑體", "Microsoft JhengHei";
}
    .ButtonNote div > p {
        margin-top: 8px;
    }


.footer {
    background-color: #000000;
    width: 100vw;
    height: 200px;
    bottom: 0;
    display: -webkit-flex;
    display: flex; /* ★ */
    -webkit-align-items: center;
            align-items: center; /* ★ */
}

.button-container {
    max-width: 100vw;
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
}
/*#endregion */

/*#region xx*/
/*#endregion */






a {
    color: rgba(0, 0, 0, 0.8)
}

    a:hover {
        color: rgba(0, 0, 0, 0.4)
    }

































video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}



.companyLogo {
    width: 100px;
    height: 100px;
    margin-top: 0px;
    padding-right: 0px
}

.companyTitleLogo {
    width: 40px;
    height: 40px;
    margin-top: 0px;
    padding-right: 0px;
    margin-left: 15px;
}









.vedio-btns {
    margin-top: 32px;
}

    .vedio-btns .btn {
        margin: 6px;
    }

.fa-play-circle {
    margin-left: 4px;
}

.SubmitBtn {
    padding: 15px 22px !important;
    border-radius: 0 !important;
    background-color: #013d7b !important;
    border-style: none;
    color: #fff
}

    .SubmitBtn:hover {
        opacity: .7;
        color: chartreuse;
    }






/* 最大時 */
@media (min-width: 600px) {
    .ContactNote {
        width: 50%;
    }

    .H {
        width: 50%;
    }

    .O {
        width: 50%;
    }

    .P {
        width: 50%;
    }

    .E {
        width: 50%;
    }


    .ContactInfo {
        width: 50%;
    }

    .C1 {
        width: 50%;
    }

    .C2 {
        width: 50%;
    }



    .ButtonNote {
        width: 20%;
    }


    .ButtonInfo {
        padding-top: 20px;
        width: 80%;
        line-height: 26px;
        font-size: 16px;
        color: #fff;
    }

        .ButtonInfo > div > p {
            margin-top: 0px;
            font-size: 5px;
            line-height: 20px;
            color: #fff;
            font-weight: lighter;
        }
}


@media screen and (max-width: 960px) {

    .Contact {
        height: 50vh; /* For 100% screen height */
        font-size: 50px;
    }

    .ContactNote {
        width: 100%;
    }

    .H {
        width: 100%;
    }

    .O {
        width: 100%;
    }

    .P {
        width: 100%;
    }

    .E {
        width: 100%;
    }

    .ContactInfo {
        width: 100%;
    }

    .C1 {
        width: 100%;
    }

    .C2 {
        width: 100%;
    }


    .ButtonNote {
        width: 100%;
    }

    .ButtonInfo {
        width: 100%;
    }
}



@media screen and (max-width: 1000px) {

    .Com {
        font-size: 56px;
    }

    .C2 .content {
        display: none;
    }

    .C2 .title {
        display: none;
    }

    .sloganHr {
        display: none;
    }
}

@media screen and (max-width: 1024px) {

    .btn {
        width: 100%;
    }

    .ButtonNote {
        display: none;
    }

    .ButtonInfo {
        padding-top: 20px;
        width: 100%;
        line-height: 16px;
        font-size: 8px;
        color: #fff;
        font-family: 'Montserrat', arial, "微軟正黑體", "Microsoft JhengHei";
    }

    .footer {
        width: 100vw;
        height: 200px;
        bottom: 0;
    }

    .sloganCotent {
        display: none;
    }
}

@media screen and (max-width: 1250px) {
    .C2 .content {
        display: none;
    }

    .C2 .title {
        display: none;
    }
}

